<template>
  <div class="home_box">
    <!-- 首页轮播图 -->
    <div class="home_top_swiper">
      <el-carousel
        trigger="click"
        height="100%"
        :interval="3000"
        class="el-car"
      >
        <el-carousel-item v-for="(item, index) in lunbotu" :key="index">
          <img :src="item" alt="" class="img" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 点燃新希望 -->
    <div class="home_hope">
      <h1 class="home_hope_titleH1">" 让学习点燃新希望 "</h1>
      <h2 class="home_hope_titleH2">成为教育行业的领跑者</h2>

      <div class="home_hope_pic">
        <img :src="homeimg.url" alt="" />
      </div>

      <div class="home_hope_div">
        <div class="home_hope_jieshao" v-html="intro"></div>

        <div class="home_hope_xiangqing" @click="ljqingkao">
          了解详情 &rharu;
        </div>
      </div>

      <!--  -->
      <ul class="home_hope_ul">
        <li>
          <span class="home_hope_ul_old">{{ jituanjianjie.years }}</span>
          +
          <div class="home_hope_ul_text">
            深耕教育行业{{ jituanjianjie.years }}+年
          </div>
        </li>
        <li>
          <span class="home_hope_ul_old">{{ jituanjianjie.teacher }}</span>
          +
          <div class="home_hope_ul_text">
            {{ jituanjianjie.teacher }}+学业规划导师
          </div>
        </li>
        <li>
          <span class="home_hope_ul_old">{{ jituanjianjie.area }}</span>
          +
          <div class="home_hope_ul_text">
            {{ jituanjianjie.area }}+平米办公场地
          </div>
        </li>
        <li>
          <span class="home_hope_ul_old">{{ jituanjianjie.student }}</span>
          万+
          <div class="home_hope_ul_text">
            服务{{ jituanjianjie.student }}万+学子毕业
          </div>
        </li>
      </ul>
    </div>

    <!-- 首页视频 -->
    <div class="home_video">
      <video
        :src="shipin.url"
        controls
        class="home_video_left"
      ></video>

      <!-- 右侧红色区域 -->
      <div class="home_video_right">
        <img src="../../assets/视频右侧图.jpg" alt="" class="img">
      </div>
    </div>

    <!-- 四大业务体系 -->
    <div class="home_box_sidayewutixi">
      <h1 class="home_box_sidayewutixi_h1">五大业务体系</h1>
      <div class="home_box_sidayewutixi_h1_divbox">
        <el-carousel
          trigger="click"
          indicator-position="none"
          height="100%"
          :interval="3000"
          :autoplay="false"
          class="el-car"
        >
          <el-carousel-item v-for="item in yewutixi" :key="item.id">
            <div class="swiper_box">
              <div class="swiper_box_left">
                <img :src="item.image" alt="" />
              </div>
              <div class="swiper_box_right">
                <h1 class="swiper_box_right_h1">{{ item.name }}</h1>
                <div class="swiper_box_right_div">
                  {{ item.intro }}
                </div>
                <div class="swiper_box_right_buttom" @click="ywtixi(item.id)">
                  了解更多 &rharu;
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 四师服务体系 -->
    <div class="home_ssfwtx">
      <h1 class="home_ssfwtx_h1">"四师"服务体系</h1>
      <div class="home_ssfwtx_div">
        <ul class="home_ssfwtx_ul">
          <li v-for="item in sishi" :key="item.id" @click="sishifuwu">
            <div class="home_ssfwtx_ul_img">
              <img :src="item.image" alt="" />
            </div>
            <div class="home_ssfwtx_box">
              <h1 class="home_ssfwtx_ul_h1">{{ item.title }}</h1>
              <div class="home_ssfwtx_ul_div">
                {{ item.content }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 荣誉资质 -->
    <div class="home_rongyu">
      <h1 class="home_rongyu_h1">荣誉资质</h1>
      <div class="home_rongyu_swiper">
        <el-carousel
          trigger="click"
          height="100%"
          indicator-position="none"
          :interval="3000"
          :autoplay="false"
          class="el-car"
        >
          <el-carousel-item v-for="(item, index) in rongyu_list" :key="index">
            <div class="home_rongyu_swiper_div">
              <ul class="home_rongyu_swiper_ul">
                <li v-for="(i, e) in item" :key="e">
                  <div class="home_rongyu_swiper_ul_div">
                    <img class="img" :src="i.image" alt="" />
                  </div>
                  <div class="home_rongyu_swiper_ul_title">
                    {{ i.title }}
                  </div>
                </li>
              </ul>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="swiper_box_right_buttom" @click="rongyuzz()">
        了解更多 &rharu;
      </div>
    </div>

    <!-- 校区分布 -->
    <div class="home_school">
      <div class="home_school_box">
        <h1 class="home_school_h1">校区分布</h1>
        <BaiduMap></BaiduMap>
      </div>
    </div>

    <!-- 旗下品牌 -->
    <div class="home_box_qixiapinpai">
      <h1 class="home_box_home_box_qixiapinpai_h1">旗下品牌</h1>
      <el-carousel
        trigger="click"
        height="80%"
        indicator-position="none"
        :interval="3000"
        :autoplay="false"
        class="el-car"
      >
        <el-carousel-item v-for="item in qixiapinpai" :key="item.id">
          <div class="qixiapinpai_box">
            <ul class="qixiapinpai_ul">
              <li v-for="i in item" :key="i.id">
                <div class="qixiapinpai_li_div">
                  <img :src="i.image" alt="" class="img" />
                </div>
                <div class="qixiapinpai_li_title">
                  {{ i.title }}
                </div>
              </li>
            </ul>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/css/swiper.min.css
// import  {group} from '@/utils/fenlei'
import BaiduMap from "@/view/home/component/map.vue";
import {
  lunbotu,
  jituanjianjie,
  shipinshouye,
  ywtx,
  ssfw,
  jjrongyu,
  qxpp,
  homeimage
} from "@/api/home";
export default {
  components: {
    BaiduMap,
  },

  data() {
    return {
      lunbotu: null,
      jituanjianjie: {},
      shipin: "",
      yewutixi: {},
      sishi: {},
      rongyu: {},
      rongyu_list: [],
      qixia: {},
      qixiapinpai: [],
      intro: "",
      homeimg:''
    };
  },
  created() {
    this.home_lunbotu();
  },
  methods: {
    async home_lunbotu() {
      const { home } = await lunbotu();
      const jianjie = await jituanjianjie();
      const shipin = await shipinshouye();
      const yewutixi = await ywtx();
      const sishi = await ssfw();
      const rongyu = await jjrongyu();
      const qixia = await qxpp();
      this.homeimg = await homeimage();
      // console.log(this.homeimg);
      this.qixia = qixia;
      this.rongyu = rongyu;
      
      // 将6个数据分成一组
      // let a = [1, 2, 3, 4, 5, 6, 7]; //原数组
      // let b = []; //新书组容器
      // let num = 6; //期望3个为一组
      this.rongyu_list = this.group(this.rongyu_list, this.rongyu, 6); //新数组
      this.qixiapinpai = this.group(this.qixiapinpai, this.qixia, 3); //新数组

      this.sishi = sishi;
      this.yewutixi = yewutixi;
      this.shipin = shipin;
      this.jituanjianjie = jianjie;
      this.intro = jianjie.intro.replace(/n/g, "&emsp;&emsp;");
      this.lunbotu = home;
    },
    // 分组方法三个参数分别是 新数组，原数组，几个分为一组
    group(newArr, arr, num) {
      if (arr.length > 0) {
        if (arr.length > num) {
          //arr 的长度大于 分组数
          newArr.push(arr.splice(0, num)); //分组
          this.group(newArr, arr, num); //递归
        } else {
          //如果长度不够分为一组，就直接把剩余的分为一组
          newArr.push(arr.splice(0, arr.length));
        }
      }
      return newArr; //返回已分组的新数组
    },
    ljqingkao() {
      this.$router.push("/intoXueMeng/qiyejieshao");
    },
    // 业务体系
    ywtixi(id) {
      if (id === 1) {
        this.$router.push("/WorkingSystem/XLJY/CRGK");
      } else if (id === 2) {
        this.$router.push("/WorkingSystem/ZCSB/ChuJi");
      } else if (id === 3) {
        this.$router.push("/WorkingSystem/ZYJY/xuexiao");
      } else if (id === 4) {
        this.$router.push("WorkingSystem/ZSB");
      }
      else if (id === 5) {
        this.$router.push("WorkingSystem/CKYX");
      }
    },
    // 四师服务
    sishifuwu() {
      this.$router.push("/XMFU/sishi");
    },
    // 荣誉
    rongyuzz() {
      this.$router.push("/intoXueMeng/jituanrongyu");
    },
    chen() {
      // console.log(1);
    },
    // chengren() {
    //   this.$router.push("/Chengkao");
    // },
    // shenbao() {
    //   this.$router.push("/shenbao");
    // },
    // guokai() {
    //   this.$router.push("/Guojiakaifang");
    // },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_box {
  // height: 2000px;
  // 顶部轮播图
  .home_top_swiper {
    width: 100%;
    height: 550px;
    // background-color: #9b0e12;
    // width: 550px;
    // swiper左右按钮
    /deep/ .el-car {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
    }
    // /deep/.el-carousel__item{
    // height: 100%;
    // }
    /deep/.el-carousel__arrow {
      // background-color: #30384d;
      width: 70px;
      height: 70px;
      // border-radius: 0px;
    }
    // 左右按钮图标
    /deep/.el-icon-arrow-left {
      font-size: 40px;
    }
    /deep/.el-icon-arrow-right {
      font-size: 40px;
    }
  }

  //  学习点燃新希望总体
  .home_hope {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-top: 100px;
    margin-bottom: 100px;
    // 学习点燃新希望标签
    .home_hope_titleH1 {
      font-size: 40px;
      color: #30384d;
      font-weight: 600;
      margin-bottom: 20px;
    }
    // 成为教育行业的领跑者
    .home_hope_titleH2 {
      font-size: 32px;
      color: #2f384a;
      margin-bottom: 50px;
    }
    // 介绍小字外面包裹的大盒子
    .home_hope_div {
      width: 1350px;
      line-height: 36px;
      font-size: 18px;
      margin: 0 auto;
      margin-top: 50px;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-content: center;
      // 介绍的所有的字
      .home_hope_jieshao {
        margin: 0 auto;
        width: 980px;
        color: #2f384a;
      }
      // 了解详情
      .home_hope_xiangqing {
        font-size: 15px;
        color: #9b0e12;
        margin-top: 50px;
        margin-bottom: 85px;
      }
      .home_hope_xiangqing:hover {
        color: red;
      }
    }
    .home_hope_ul {
      width: 1350px;
      display: flex;
      margin: 0 auto;
      li {
        flex: 1;
        // 年龄介绍
        .home_hope_ul_old {
          font-size: 60px;
          font-weight: 600;
          color: #9b0e12;
        }

        // 介绍行业
        .home_hope_ul_text {
          font-size: 20px;
        }
      }
    }
    .home_hope_pic {
      margin: 0 auto;
      // height: 750px;
      width: 1400px;
      img{
        width: 100%;
        height:100%;
      }
      // height: 200px;
      // background-color: red;
    }
  }

  // 首页视频
  .home_video {
    display: flex;
    width: 1400px;
    height: 515px;
    margin: 0 auto;
    .home_video_left {
      width: 875px;
      object-fit: fill;
    }
    .home_video_right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 530px;
      // height: 515px;
      font-size: 16px;
      // background-image: url("../../assets/视频背景图.png");
      background-color: #9b0e12;
      
      img{
        width: 90%;
        height: 90%;
        object-fit: cover;
      }

      // 右侧文字介绍
      // .home_video_right_div {
      //   margin-top: -30px;
      //   width: 500px;
      //   // height: 100px;
      //   margin-left: 50px;
      //   position: relative;
      //   color: #fff;
      //   .home_video_right_img {
      //     height: 37px
      //   }
      //   // 互动授课
      //   .home_video_right_span {
      //     position: absolute;
      //     top: 8px;
      //     left: 50px;
      //     font-weight: 600;
      //     // font-size: 18px;
      //   }
      //   .home_video_right_div_div1 {
      //     position: absolute;
      //     top: 45px;
      //     left: 50px;
      //     width: 300px;
      //     // font-size: 15px;
      //   }
      //   // .home_video_right_div_div2 {
      //   //   position: absolute;
      //   //   top: 75px;
      //   //   left: 50px;
      //   // }
      // }
    }
  }

  // 轮播图 四大业务体系
  .home_box_sidayewutixi {
    // swiper左右按钮
    /deep/.el-carousel__arrow {
      background-color: #30384d;
      width: 60px;
      height: 60px;
      border-radius: 0px;
    }
    // 左右按钮图标
    /deep/.el-icon-arrow-left {
      font-size: 32px;
    }
    /deep/.el-icon-arrow-right {
      font-size: 32px;
    }
    height: 500px;
    width: 1400px;
    margin: 0 auto;
    margin-top: 100px;

    .home_box_sidayewutixi_h1 {
      font-size: 40px;
      font-weight: 800;
      color: #30384d;
      margin-bottom: 80px;
      text-align: center;
    }
    .home_box_sidayewutixi_h1_divbox {
      height: 580px;
      width: 1400px;
      /deep/ .el-car {
        height: 530px;
        width: 100%;
      }
    }
    .swiper_box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;

      //轮播图左侧图片
      .swiper_box_left {
        width: 565px;
        height: 100%;
        background-color: red;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      // 轮播图 右侧文字介绍
      .swiper_box_right {
        height: 100%;
        padding-left: 50px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        //学历教育
        .swiper_box_right_h1 {
          color: #30384d;
          font-size: 30px;
          font-weight: 600;
        }
        // 学历教育介绍
        .swiper_box_right_div {
          text-indent: 2em;
          font-size: 18px;
          line-height: 30px;
          // margin-top: 40px;
          // margin-bottom: 10px;
        }
        // 了解更多按钮
        .swiper_box_right_buttom {
          width: 160px;
          height: 40px;
          color: #fff;
          font-size: 15px;
          background-color: #9b0e12;
          text-align: center;
          line-height: 40px;
        }
        .swiper_box_right_buttom:hover {
          opacity: 0.6;
        }
        .swiper_box_right_buttom:active {
          opacity: 0.8;
        }
      }
    }
  }

  // 四师服务体系
  .home_ssfwtx {
    width: 1400px;
    height: 500px;
    margin: 0 auto;
    margin-top: 250px;
    margin-bottom: 80px;
    // 标签
    .home_ssfwtx_h1 {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #30384d;
      margin-bottom: 75px;
    }
    .home_ssfwtx_div {
      width: 100%;
      height: 380px;
      .home_ssfwtx_ul {
        display: flex;
        height: 380px;
        li {
          // width: 340px;
          // flex: 1;
          width: 23%;
          margin: 0 8px;
          height: 100%;
          .home_ssfwtx_ul_img {
            height: 230px;
            width: 100%;
            background-color: #9b0e12;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .home_ssfwtx_box {
            width: 285px;
            height: 120px;
            padding-left: 15px;

            .home_ssfwtx_ul_h1 {
              text-align: center;
              color: #30384d;
              font-size: 18px;
              font-weight: 600;
              margin-top: 15px;
              margin-bottom: 15px;
            }
            .home_ssfwtx_ul_div {
              text-indent: 2em;
              // text-align: center;
              margin: 0 auto;
              font-size: 14px;
              color: #30384d;
            }
          }
        }
      }
    }
  }

  // 荣誉资质
  .home_rongyu {
    /deep/ .el-car {
      height: 100%;
      width: 100%;
    }
    // swiper左右按钮
    /deep/.el-carousel__arrow {
      background-color: #30384d;
      width: 60px;
      height: 60px;
      border-radius: 0px;
    }
    // 左右按钮图标
    /deep/.el-icon-arrow-left {
      font-size: 32px;
    }
    /deep/.el-icon-arrow-right {
      font-size: 32px;
    }
    width: 100%;
    height: 900px;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: column;
    background-color: #9b0e12;
    box-sizing: border-box;
    .home_rongyu_h1 {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #fff;
    }
    .home_rongyu_swiper {
      width: 1450px;
      height: 600px;
      // height: fit-content;
      // background-color: blue;
      margin: 0 auto;
      .home_rongyu_swiper_div {
        width: 1240px;
        // height: 690px;
        margin: 0 auto;
        .home_rongyu_swiper_ul {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            background-color: #fff;
            width: 400px;
             display: flex;
             justify-content: center;
             align-items: center;
            // padding: 20px;
            // height: 340px;
            &:nth-child(-n + 3) {
              margin-bottom: 20px;
            }
            .home_rongyu_swiper_ul_div {
              flex: 1;
              margin: 15px;
              background-color: #9b0e12;
            }
            .home_rongyu_swiper_ul_title {
              color: #30384d;
              font-size: 18px;
              font-weight: 600;
              margin-top: 25px;
              text-align: center;
            }
          }
        }
      }
    }
    // 了解更多按钮
    .swiper_box_right_buttom {
      width: 160px;
      height: 40px;
      color: #9b0e12;
      font-size: 15px;
      background-color: #fff;
      text-align: center;
      line-height: 40px;
      margin: 0 auto;
      // margin-top: 50px;
    }
    .swiper_box_right_buttom:hover {
      opacity: 0.9;
    }
    .swiper_box_right_buttom:active {
      opacity: 0.8;
    }
  }

  // 校区分布
  .home_school {
    width: 100%;
    height: 470px;
    margin-top: 50px;

    .home_school_box {
      width: 1400px;
      height: 470px;
      margin: 0 auto;
      .home_school_h1 {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #30384d;
        margin-bottom: 50px;
      }
    }
  }

  // 轮播图 旗下品牌
  .home_box_qixiapinpai {
    height: 450px;
    width: 1400px;
    margin: 0 auto;
    margin-top: 100px;

    // swiper左右按钮
    /deep/ .el-car {
      width: 100%;
      height: 100%;
    }
    /deep/.el-carousel__arrow {
      background-color: #30384d;
      width: 60px;
      height: 60px;
      border-radius: 0px;
    }
    // 左右按钮图标
    /deep/.el-icon-arrow-left {
      font-size: 32px;
    }
    /deep/.el-icon-arrow-right {
      font-size: 32px;
    }
    
    .home_box_home_box_qixiapinpai_h1 {
      font-size: 40px;
      font-weight: 800;
      color: #30384d;
      margin-bottom: 50px;
      text-align: center;
    }
    .qixiapinpai_box {
      width: 1230px;
      height: 325px;
      margin: 0 auto;
      display: flex;
      .qixiapinpai_ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        li {
          width: 392px;
          height: 100%;
          background-color: #fff;
          border: 1px solid #9aa7d0;
          .qixiapinpai_li_div {
            height: 234px;
            width: 100%;
            background-color: #9b0e12;
          }
          .qixiapinpai_li_title {
            color: #30384d;
            font-size: 18px;
            font-weight: 600;
            margin-top: 25px;
            text-align: center;
          }
        }
      }
    }
  }

  // .home_box_kaoshi {
  //   // margin-top: 20px;
  //   width: 1300px;
  //   // height: 300px;
  //   // background-color: blue;
  //   margin: 0 auto;
  //   // background-color: red;
  //   display: flex;
  //   justify-content: space-around;
  //   img {
  //     width: 400px;
  //     // height: 80px;
  //     border-radius: 10px;
  //     object-fit: cover;
  //   }
  // }
}
</style>