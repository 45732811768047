<template>
  <div class="box">
    <!-- 头部 -->
    <div class="nav_div">
      <div class="nav_left_div">
        <img src="../src/assets/官网logo.png" alt="" class="nav_left_div_img" />
      </div>
      <!-- <div class="nav_center_div"> -->
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#9b0e12"
        router
      >
        <!--  text-color='#000' -->
        <!-- active-text-color="#9b0e12" -->
        <el-menu-item index="/"> 首页 </el-menu-item>
        <el-submenu index="/intoXueMeng">
          <template slot="title">
            <!-- <div class="nav_div_title" @click="zoujin"> 走进学盟 </div> -->
            走进学盟
          </template>
          <el-menu-item index="/intoXueMeng/qiyejieshao">企业介绍</el-menu-item>
          <el-menu-item index="/intoXueMeng/fazhanlicheng"
            >发展历程</el-menu-item
          >
          <el-menu-item index="/intoXueMeng/wenhualinian"
            >文化理念</el-menu-item
          >
          <el-menu-item index="/intoXueMeng/gaoxiaoguanhuai"
            >高校关怀</el-menu-item
          >
          <el-menu-item index="/intoXueMeng/jituanrongyu"
            >集团荣誉</el-menu-item
          >
        </el-submenu>
        <el-submenu index="/NewsCenter">
          <template slot="title">
            <!-- <div class="nav_div_title" @click="zoujin"> 走进学盟 </div> -->
            新闻中心
          </template>
          <el-menu-item index="/NewsCenter/jituandongtai"
            >集团动态</el-menu-item
          >
          <el-menu-item index="/NewsCenter/hangyedongtai"
            >行业动态</el-menu-item
          >
          <el-menu-item index="/NewsCenter/shipinzhongxin"
            >视频中心</el-menu-item
          >
          <el-menu-item index="/NewsCenter/xiaoqugushi">校区故事</el-menu-item>
        </el-submenu>

        <el-menu-item index="/WorkingSystem">业务体系</el-menu-item>
        <el-submenu index="/XMFU">
          <template slot="title">
            <div class="nav_div_title">学盟服务</div>
          </template>
          <el-menu-item index="/XMFU/sishi">四师服务</el-menu-item>
          <el-menu-item index="/XMFU/xuanze" class="font"
            >选择学盟的理由</el-menu-item
          >
          <el-menu-item index="/XMFU/fengcai">学员风采</el-menu-item>
        </el-submenu>
        <el-menu-item index="/tuanjian">集团党建</el-menu-item>
        <el-menu-item index="/lianxi">联系我们</el-menu-item>
      </el-menu>
      <!-- </div> -->

      <div class="nav_right_div">
        <img
          src="../src/assets/电话图标(2).png"
          alt=""
          class="nav_right_div_img"
        />
        <span class="nav_right_div_span">400-921-9311</span>
      </div>
    </div>
    <!-- 路由 -->
    <div class="middle_div">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <!-- 三张照片 -->
    <div class="home_box_kaoshi">
      <img src="~@/assets/111333_01.png" alt="" @click="chengren" />
      <!-- <img src="~@/assets/111333_05.png" alt="" @click="shenbao" /> -->
      <img src="~@/assets/111333_03.png" alt="" @click="guokai" />
    </div>
    <!-- <span></span> -->
    <!-- 底部 -->
    <div class="buttom_div">
      <div class="buttom_nav">
        <div class="buttom_nav_left">
          <ul>
            <li class="frist_li" @click="zoujinxuemeng">走进学盟</li>
            <li @click="qiyejianjie">企业简介</li>
            <li @click="fazhanlicheng">发展历程</li>
            <li @click="wenhua">文化理念</li>
            <li @click="gaoxiao">高校关怀</li>
            <li @click="rongyu">集团荣誉</li>
          </ul>
          <ul>
            <li class="frist_li" @click="xinwenzhongxin">新闻中心</li>
            <li @click="xinwenzhongxin">集团动态</li>
            <li @click="hangye">行业动态</li>
            <li @click="shipin">视频中心</li>
            <li @click="xiaoqu">校区故事</li>
          </ul>
          <ul>
            <li class="frist_li" @click="yewutixi">业务体系</li>
            <!-- <li @click="yewutixi">职称申报</li> -->
            <li @click="xuelijiaouy">学历教育</li>
            <li @click="zhiye">职业教育</li>
            <li @click="zhuanshengben">专升本培训</li>
          </ul>
        </div>
        <div class="buttom_nav_center">
          <ul>
            <li class="frist_li">联系我们</li>
            <li>客服电话:&emsp;400-921-9311</li>
            <li class="youxiang">邮箱:&emsp;jnxmedu@163.com</li>
            <li class="dizhi">学盟集团总部地址 :</li>
            <li class="dizhi">山东大学洪家楼校区1号研究生楼101-112室</li>
          </ul>
        </div>
        <div class="buttom_nav_right">
          <ul>
            <li>
              <div class="li_div">
                <img src="../src/assets/学盟云课堂.png" alt="" />
              </div>
              <div>学盟云课堂</div>
              <div>公众号</div>
            </li>
            <li>
              <div class="li_div">
                <img src="../src/assets/职帮.jpg" alt="" />
              </div>
              <div>职邦</div>
              <div>公众号</div>
            </li>
            <li>
              <div class="li_div">
                <img src="../src/assets/集团二维码.jpg" alt="" />
              </div>
              <div>学盟集团</div>
              <div>公众号</div>
            </li>
          </ul>
          <div class="buttom_nav_right_search">
            <img src="../src/assets/让学习点燃新希望_01.png" alt="" />
          </div>
        </div>
      </div>
      <div class="buttom_top">
        <div class="link">合作伙伴</div>
        <ul class="link_item">
          <li v-for="item in partners" :key="item.id">
            <a :href="item.url" target="value">
              <img :src="item.image" alt="" class="img" />
            </a>
          </li>
        </ul>
        d
      </div>
      <div class="buttom_top">
        <div class="link">友情链接</div>
        <ul class="link_item">
          <li v-for="item in link" :key="item.id">
            <a class="alink" target="value" :href="item.url">{{
              item.title
            }}</a>
          </li>
        </ul>
      </div>
      <div class="buttom_bottom">
        版权所有&copy;山东学盟教服集团/
        <a target="_blank" href="https://beian.miit.gov.cn/">鲁ICP备19028062号</a>
      </div>
    </div>
  </div>
</template>

<script>
import { links, partners } from "@/api/APP";
export default {
  data() {
    return {
      activeIndex: "1",
      navtab: false,
      link: {},
      partners: {},
    };
  },
  created() {
    this.linslins();
    this.partner();
  },
  methods: {
    // use51La() {
    //   let script = document.createElement("script"); //页面上html部分，要添加的地方
    //   script.src = "/static/tj.js"; //自己处理后的脚本文件
    //   script.type = "text/javascript";
    //   script.id = "tj-51la"; //自定义id
    //   let tj = document.getElementById("tj");
    //   tj.insertBefore(script, tj.children[0]); //插入到前部
    // },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 150) {
        this.navtab = true;
      } else if (scrollTop < 150) {
        this.navtab = false;
      }
    },
    async linslins() {
      this.link = await links();
    },
    async partner() {
      this.partners = await partners();
    },

    // 选择框
    handleSelect(key) {
      this.$router.push(key);
    },
    // 底部三个照片
    chengren() {
      // this.$router.push("/Chengkao");
    },
    shenbao() {
      this.$router.push("/shenbao");
    },
    guokai() {
      // this.$router.push("/Guojiakaifang");
    },

    // 底部按钮
    zoujinxuemeng() {
      this.$router.push("/intoXueMeng/qiyejieshao");
    },
    qiyejianjie() {
      this.$router.push("/intoXueMeng/qiyejieshao");
    },
    fazhanlicheng() {
      this.$router.push("/intoXueMeng/fazhanlicheng");
    },
    wenhua() {
      this.$router.push("/intoXueMeng/wenhualinian");
    },
    gaoxiao() {
      this.$router.push("/intoXueMeng/gaoxiaoguanhuai");
    },
    rongyu() {
      this.$router.push("/intoXueMeng/jituanrongyu");
    },

    xinwenzhongxin() {
      this.$router.push("/NewsCenter/jituandongtai");
    },
    hangye() {
      this.$router.push("/NewsCenter/hangyedongtai");
    },
    shipin() {
      this.$router.push("/NewsCenter/shipinzhongxin");
    },

    xiaoqu() {
      this.$router.push("/NewsCenter/xiaoqugushi");
    },

    yewutixi() {
      this.$router.push("/WorkingSystem/ZCSB/ChuJi");
    },
    xuelijiaouy() {
      this.$router.push("/WorkingSystem/XLJY/CRGK");
    },
    zhiye() {
      this.$router.push("/WorkingSystem/ZYJY/xuexiao");
    },
    zhuanshengben() {
      this.$router.push("/WorkingSystem/ZSB");
    },
    ywtx() {
      this.$router.push("/WorkingSystem/WorkingSY");
    },
  },
  mounted() {
    // this.use51La()
    var div = document.querySelectorAll(".el-menu--popup");
    div.forEach((item) => {
      // item.style.minWidth = "115px";
      item.style.minWidth = "0.61rem";
      // console.log(item);
      // item.style.textAlign='center';
    });

    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
// html{-webkit-text-size-adjust:none;}
/* 小的不会被彻底覆盖，大的却会 */

/* PC端样式 */
// @media screen and (min-width: 768px) {
//   body {
//     width: 768px;
//     margin: 0 auto;
//   }
// }

// /* 手机端样式 */
// @media screen and (max-width: 767px) {
//   .body {
//     width: 100%;
//     margin: 0;
//   }
// }
// /deep/ .el-menu--popup-bottom-start{
//   width: 120px;
// }

// .el-menu--horizontal .el-menu .el-menu-item,
// .el-menu--horizontal .el-menu .el-submenu__title {
//   // background-color: #FFF;
//   // border: 1px solid blue;
//   // text-align: center;
//   font-size: 17px;
//   // width: 80px;
//   // float: none;
//   height: 50px;
//   line-height: 50px;
//   // padding: 0 10px;
//   color: #000;
//   text-align: center;
// }
// 修改下拉菜单的
.el-menu--horizontal .el-menu .el-menu-item {
  // width: 1000px;
  // padding: 0px -100px;
  // background-color: blue;
  font-size: 17px;
  height: 50px;
  text-align: center;
  color: #000;
}

// 鼠标经过下拉框每一项时的hover效果
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #9b0e12;
}
::v-deep .el-submenu__title {
  .el-icon-arrow-down {
    display: none;
  }
}
::v-deep .el-submenu__title:active {
  border-bottom-color: #9b0e12;
}

// /deep/ .el-menu--popup {
//   min-width: 10px !important;
//    width: 130px;
// }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// html{
//   width: 100%;
//   height: 100%;
// }
.box {
  // position: relative;
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  .font {
    font-size: 16px;
  }
  .nav_div {
    // flex: 1;
    height: 115px;
    width: 1500px;
    display: flex;
    margin: 0 auto;
    .nav_div_title {
      // padding-left: 7px;
      // padding-right: 7px;
      padding: 0px 10px;
    }
    // 中间导航栏
    .el-menu-demo {
      width: 800px;
      // background-color: blue;
      // height: 68px;
      margin: auto 10px;
      padding-top: 10px;
      border-bottom: none;
      // 每一项
      .el-menu-item {
        font-size: 19px;
        padding: 0 20px;
        font-weight: bold;
        color: #31374c !important;
        border-bottom-color: #9b0e12;
        // transition-delay:9999s;
      }
      // 每一项的激活
      .el-menu-item.is-active {
        color: #9b0e12 !important;
        //  color: #fff !important;
      }
      // 每一项的hover
      .el-menu-item:hover {
        background-color: #9b0e12;
        color: #fff !important;
      }
      /* 深选择器：如果相对设置了scoped的子组件里的元素进行控制可以使用'>>>'或者'deep'设置选中或悬浮的颜色*/
      // /deep/.is-opened {
      //   color: #fff !important;
      //   background-color: #9b0e12;
      // }
      .el-submenu /deep/ .el-submenu__title {
        font-size: 19px;
        font-weight: bold;
        color: #31374c !important;
        border-bottom-color: #9b0e12;
      }
      // .el-submenu /deep/ .is-active {
      // color: #9b0e12 !important;
      // }
      .el-submenu /deep/ .el-submenu__title:hover {
        background-color: #9b0e12;
        color: #fff !important;
      }
      // .el-submenu /deep/ .el-submenu__title:active {
      // background-color: #9b0e12;
      // color: #fff !important;
      //  border-bottom-color: #9b0e12;
      // }
    }
    // 左侧logo
    .nav_left_div {
      position: relative;
      width: 320px;
      // height: 115px;
      .nav_left_div_img {
        object-fit: cover;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        image-rendering: -webkit-optimize-contrast;
      }
    }
    // 右侧电话
    .nav_right_div {
      width: 300px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: blue;
      margin-left: -30px;
      .nav_right_div_img {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        margin-top: -1px;
        // background-color: blue;
        vertical-align: middle;
        // background-image: url('../src/assets/电话图标(2).png');
        // background-size: 100% 100%;
      }
      .nav_right_div_span {
        font-size: 24px;
        font-weight: 800;
        color: #9b0e12;
        vertical-align: middle;
      }
    }
    /deep/ .el-menu--horizontal {
      display: block;
    }
  }
  .middle_div {
    //  flex: 1;
    margin-bottom: 60px;
    background-color: #fdfdfe;
  }
  // 下面照片
  .home_box_kaoshi {
    width: 1300px;
    margin: 0 auto;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
    img {
      width: 400px;
      // height: 80px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  // 版权底部所有信息
  .buttom_div {
    width: 100%;
    background-color: #0d152a;
    padding-top: 10px;
    .buttom_top {
      width: 1400px;
      height: 30px;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      .link {
        // width: 60px;ss
        height: 100%;
        background-color: #9b0e12;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 20px;
        border-radius: 10px;
        font-size: 13px;
        color: #fff;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .link_item {
        height: 100%;
        display: flex;
        li {
          margin-left: 15px;
          height: 100%;
          // width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          a {
            height: 100%;
            font-size: 11px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none; /* 去除默认的下划线 */
            outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
            color: #fff; /* 去除默认的颜色和点击后变化的颜色 */
            .img {
              border-radius: 10px;
              width: 90px;
              height: 100%;
              object-fit: cover;
            }
          }
          .alink {
            width: 90px;
            border-radius: 10px;
            //  background-color: #fff;
            // padding-right: 5px;
            // color: #000;
            font-size: 13px;
            font-weight: 600;
            // padding-left: 5px;
          }
          &:hover a {
            color: #9b0e12 !important;
            opacity: 0.8;
          }
        }
      }
    }
    .buttom_nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 220px;
      width: 1360px;
      margin: 0 auto;
      margin-top: 20px;

      // 左侧盒子 走进学盟
      .buttom_nav_left {
        display: flex;
        justify-content: space-between;
        flex: 1;
        height: 100%;
        padding-right: 90px;
        border-right: 1px solid #fff;
        color: #fff;
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          .frist_li {
            font-size: 19px;
            font-weight: 600;
          }
          li {
            font-size: 14px;
            margin-bottom: 15px;
          }
          li:hover {
            color: #9b0e12;
          }
          li:active {
            color: red;
          }
        }
      }
      // 中间盒子 联系我们
      .buttom_nav_center {
        flex: 0.8;
        height: 100%;
        border-right: 1px solid #fff;
        color: #fff;
        padding-left: 70px;
        ul {
          .frist_li {
            font-size: 19px;
            font-weight: 600;
          }
          li {
            font-size: 14px;
            margin-bottom: 18px;
          }
          .youxiang {
            margin-bottom: 30px;
          }
        }
        // .dizhi{
        //   width: 250px;
        //   // font-size: 22px;
        //   font-size: 18px;
        //   font-weight: 600;
        // }
      }
      // 右边盒子 二维码
      .buttom_nav_right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        height: 100%;
        text-align: center;
        padding-left: 40px;
        ul {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          font-size: 15px;
          color: #fff;
          li {
            // text-align: center;
            width: 94px;
            height: 94px;
            .li_div {
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        .buttom_nav_right_search {
          margin-top: 15%;
          img {
            width: 70%;
            height: 70%;
            object-fit: cover;
          }
        }
      }
    }
    // 下面的版权盒子
    .buttom_bottom {
      width: 1360px;
      margin: 0 auto;
      color: #fff;
      border-top: 1px solid #fff;
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none; /* 去除默认的下划线 */
        outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
        color: #fff; /* 去除默认的颜色和点击后变化的颜色 */
        &:hover{
          // color: #9b0e12;
        }
      }
    }
  }
}
</style>