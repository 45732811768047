<template>
  <div class="fzlc_box">
    <!--  -->
    <div class="fzlc_box_item" v-for="item in fazhanlicheng" :key="item.id"> 
      <div class="fzlc_box_item_left">
        <div class="fzlc_box_item_left_top">{{item.title}}</div>
        <div class="fzlc_box_item_left_bottom">{{item.times_left}} —— {{item.times_right}}</div>
      </div>
      <div class="fzlc_box_item_right">
          <div class="fzlc_box_item_right_top" v-html="item.intro">
          </div>
          <div class="fzlc_box_item_right_bottom">
            <img :src="item.image" alt="" class="img">
          </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {xuemenghistory} from '@/api/intoxuemeng'
export default {
  data(){
    return{
      fazhanlicheng:{}
    }
  },
  created(){
    this.create()
  },
  methods:{
    async create(){
        const fazhan = await xuemenghistory()
        // console.log(fazhan);
        this.fazhanlicheng=fazhan
    }
  }
  
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fzlc_box {
   margin-top: 50px;
  .fzlc_box_item {
    width: 1000px;
    height: fit-content;
    display: flex;
    .fzlc_box_item_left {
      position: relative;
      width: 160px;
      border-right: 1px solid #dee1e3;

      // 小红点
      &::after {
        position: absolute;
        top: 25px;
        left: 155px;
        // display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #9b0e12;
        content: "";
      }
      .fzlc_box_item_left_top {
        font-size: 30px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .fzlc_box_item_left_bottom {
        font-size: 15px;
        color: #a1a8b1;
      }
    }

    .fzlc_box_item_right{
      margin-top: 20px;
      margin-left: 35px;
      width: 800px;
      height: 100%;
      .fzlc_box_item_right_top{
        font-size: 18px;
        margin-bottom: 40px;
      }
      .fzlc_box_item_right_bottom{
        width: 480px;
        height: 250px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>