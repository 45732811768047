<template>
  <div class="box">
    <!-- <img src="../../assets/成考页面1.jpg" alt=""> -->
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // body{
  //   position: relative;
  // }
  // .box{
  //   position: absolute;
  //   top: 0;
  // }
</style>