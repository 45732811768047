<template>
  <div class="ywtx_item">
    <div class="ywtx_item_top">
      <div class="ywtx_item_top_left">
        <div class="icon">
          <img src="~@/assets/引号.png" alt="" />
        </div>
        <div class="text">{{ idnum.name }}</div>
      </div>
      <div class="ywtx_item_top_right">
        <img class="img" :src="idnum.image_big" alt="" />
      </div>
    </div>
    <div class="ywtx_item_middle">
      {{ idnum.intro }}
    </div>

    <!-- 跳转整体盒子 -->
    <div class="ywtx_item_bottom">
      <ul class="ywtx_div_content_right_router">
        <li
          v-for="item in type"
          :key="item.id"
          @click="tiaozhuan(item.id)"
          :class="item.jinzhi === false ? 'jinzhi' : ''"
        >
          <div class="ywtx_div_content_router_img">
            <img :src="item.url" alt="" class="img" />
          </div>
          <div class="ywtx_div_content_router_li_div">
            <span>{{ item.name }}</span>
            <div>了解详情 &rharu;</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idnum: {},
      type: {},
      b: 0,
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        // console.log(val);
        if (val.params.id == undefined) {
          this.idnum = JSON.parse(localStorage.getItem("idnum"));
          this.type = JSON.parse(localStorage.getItem("type"));
        } else {
          this.create();
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  created() {
  },
  methods: {
    async create() {
      this.idnum = await JSON.parse(this.$route.params.id);
      localStorage.setItem("idnum", JSON.stringify(this.idnum));
      // var routerid = JSON.parse(this.$route.params.id).id;
      // localStorage.setItem("routerid", JSON.stringify(routerid));

      this.type = await JSON.parse(this.$route.params.type);
      localStorage.setItem("type", JSON.stringify(this.type));
    },
    tiaozhuan(id) {
      if (id === 1) {
        this.$router.push("/WorkingSystem/XLJY/CRGK");
      } else if (id === 2) {
        this.push("/WorkingSystem/XLJY/ZK");
      } else if (id === 3) {
        this.$router.push("/WorkingSystem/XLJY/GJKF");
      } else if (id === 4) {
        this.$router.push("/WorkingSystem/ZCSB/ChuJi");
      } else if (id === 5) {
        this.$router.push("/WorkingSystem/ZCSB/ZhongJi");
      } else if (id === 6) {
        this.$router.push("/WorkingSystem/ZCSB/GaoJi");
      } else if (id === 7) {
        this.$router.push("/WorkingSystem/ZYJY/xuexiao");
      } else if (id === 8) {
        this.$router.push("/WorkingSystem/ZYJY/peixun");
      } else if (id === 9) {
        this.$router.push("/WorkingSystem/ZSB");
      } else if (id === 17) {
        this.$router.push("/WorkingSystem/CKYX");
      }
    },

    // 判断存储
    panduan() {
      if (localStorage.getItem("idnum") && localStorage.getItem("type")) {
        // console.log(this.idnum,'idnum');
        if (
          this.idnum !== JSON.parse(localStorage.getItem("idnum")) &&
          this.type !== JSON.parse(localStorage.getItem("type"))
        ) {
          // this.create();
          // console.log('存储的和 data数据不相同');
          // console.log(this.idnum, "this.idnum");
          // console.log(JSON.parse(localStorage.getItem("idnum")));
        } else {
          this.idnum = JSON.parse(localStorage.getItem("idnum"));
          this.type = JSON.parse(localStorage.getItem("type"));
          // console.log("数据相同");
        }
      } else {
        this.create();
        // console.log(1111, "没有存储");
      }
    },
    // 存储params传过来的数据 使页面刷新不丢失
    parma() {
      // var paramsData = localStorage.getItem("paramsData");
      if (localStorage.getItem("paramsData")) {
        this.idnum = JSON.parse(localStorage.getItem("paramsData"));
      } else {
        this.idnum = localStorage.setItem(
          "paramsData",
          JSON.stringify(this.idnum)
        );
      }
    },
    parma1() {
      // var paramsData1 =;
      if (localStorage.getItem("paramsData1")) {
        this.type = JSON.parse(localStorage.getItem("paramsData"));
      } else {
        this.type = localStorage.setItem(
          "paramsData1",
          JSON.stringify(this.type)
        );
      }
    },
  },
  // 页面销毁之前
  // beforeDestroy() {
  //   localStorage.removeItem("idnum");
  //   localStorage.removeItem("type");
  // },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ywtx_item {
  margin: 0 auto;
  width: 1400px;
  .ywtx_item_top {
    margin-top: 65px;
    width: 100%;
    height: 330px;
    // background-color: blue;
    display: flex;
    .ywtx_item_top_left {
      width: 317px;
      height: 100%;
      background-color: #9b0e12;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: 35px;
        left: 40px;
      }
      .text {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
    .ywtx_item_top_right {
      flex: 1;
    }
  }

  .ywtx_item_middle {
    margin-bottom: 95px;
    margin-top: 60px;
    font-size: 17px;
    line-height: 36px;
  }
  .ywtx_item_bottom {
    // 跳转的整体盒子
    .ywtx_div_content_right_router {
      margin-top: 55px;
      width: 100%;
      // background-color: blue;
      height: 171px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      li {
        width: 23%;
        height: 80%;
        border: 1px solid #a7aeb7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        &:hover {
          border: 1px solid #9b0e12;
        }
        &:active {
          opacity: 0.6;
        }
        .ywtx_div_content_router_img {
          // margin-left: 70px;
          // margin-top: 60px;
          width: 55px;
          // height: 50px;
          object-fit: cover;
          // background-color: red;
        }
        .ywtx_div_content_router_li_div {
          margin-left: 25px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          span {
            width: 100%;
            // background-color: red;
            display: inline-block;
            font-size: 20px;
            font-weight: 600;
            color: #32374f;
          }
          div {
            font-size: 17px;
            color: #9b0e12;
          }
        }
      }
    }
  }
  // 鼠标经过禁止跳转样式
  .jinzhi {
    &:hover {
      border: 1px solid #a7aeb7;
      filter: grayscale(100%);
      cursor: not-allowed;
    }
  }
}
</style>