import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/view/home/index.vue'
import intoXueMeng from '@/view/intoxuemeng'
import qiyejieshao from '@/view/intoxuemeng/qiyejieshao.vue'
import fazhanlicheng from '@/view/intoxuemeng/fazhanlicheng.vue'
import wenhualinian from '@/view/intoxuemeng/wenhualinian.vue'
import gaoxiaoguanhuai from '@/view/intoxuemeng/gaoxiaoguanhuai.vue'
import jituanrongyu from '@/view/intoxuemeng/jituanrongyu.vue'

import NewsCenter from '@/view/NewsCenter'
import hangyedongtai from '@/view/NewsCenter/hangyedongtai.vue'
import jituandongtai from '@/view/NewsCenter/jituandongtai'
import shipinzhongxin from '@/view/NewsCenter/shipinzhongxin.vue'
import xiaoqugushi from '@/view/NewsCenter/xiaoqugushi.vue'

// 业务体系
import WorkingSystem from '@/view/workingSystem'
import WorkingSY from '@/view/workingSystem/shouye.vue'
import WorkingItem from '@/view/workingSystem/workingItem.vue'
// 学历教育

import XLJY from '@/view/Xuelijiaoyu'
import CRGK from '@/view/Xuelijiaoyu/chengrengaokao.vue'
import ZK from '@/view/Xuelijiaoyu/zikao.vue'
import GJKF from '@/view/Xuelijiaoyu/guojiakaifang.vue'
import DDZZ from '@/view/Xuelijiaoyu/dianda.vue'

// 职称申报
import ZCSB from '@/view/zhichengshenbao'
import ChuJi from '@/view/zhichengshenbao/chuji.vue'
import ZhongJi from '@/view/zhichengshenbao/zhongji.vue'
import GaoJi from '@/view/zhichengshenbao/gaoji.vue'
import ZhengGaoJi from '@/view/zhichengshenbao/zhenggaoji.vue'


// 职业教育
import ZYJY from '@/view/zhiyejiaoyu'
import xuexiao from '@/view/zhiyejiaoyu/zhiyexuexiao.vue'
import peixun from '@/view/zhiyejiaoyu/zhiyepeixun.vue'

// 学盟服务
import XMFU from '@/view/xuemengfuwu'
import sishi from '@/view/xuemengfuwu/sishifuwu.vue'
import xuanze from '@/view/xuemengfuwu/xuanzexuemeng.vue'
import fengcai from '@/view/xuemengfuwu/xueyuanfengcai.vue'
// 专升本
import ZSB from '@/view/zhuanshengben'


// 成考院校
import CKYX from '@/view/yuanxiao'
import xiangqing from '@/view/yuanxiao/chengkaoyuanxiao'

// 集团团建
import tuanjian from '@/view/tuanjian'
// 联系我们
import lianxi from '@/view/lianxiwomen'
// 用户服务
// import User from '@/view/user'

// 照片
import chengkao from '@/view/pic/chengkao'
import guojiakaifang from '@/view/pic/guojiakaifang'
import shenbao from '@/view/pic/shenbao'

// 新闻中心
import jituanItem from '@/view/xinwenzhongxinItem/jituanItem'
import hangyeItem from '@/view/xinwenzhongxinItem/hangyeItem'
import shipinItem from '@/view/xinwenzhongxinItem/shipinItem'
import xiaoquItem from '@/view/xinwenzhongxinItem/xiaoquItem'
// import kongbai from '@/view/xinwenzhongxinItem/kongbai.vue'




Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        component: Home
        // redirect: '/home'
    },
    // {
    //     path: '/',
    //     component: Home
    // },
    // 走进学盟
    {
        path: '/intoXueMeng',
        component: intoXueMeng,
        redirect: '/intoXueMeng/qiyejieshao',
        children: [
            {
                path: 'qiyejieshao',
                name: 'qiyejieshao',
                component: qiyejieshao,
            },
            {
                path: 'fazhanlicheng',
                name: 'fazhanlicheng',
                component: fazhanlicheng,
            },
            {
                path: 'wenhualinian',
                name: 'wenhualinian',
                component: wenhualinian,
            },
            {
                path: 'gaoxiaoguanhuai',
                name: 'gaoxiaoguanhuai',
                component: gaoxiaoguanhuai,
            },
            {
                path: 'jituanrongyu',
                name: 'jituanrongyu',
                component: jituanrongyu,
            }
        ]
    },
    // 新闻中心
    {
        path: '/NewsCenter',
        component: NewsCenter,
        redirect: '/NewsCenter/jituandongtai',
        children: [
            {
                path: 'jituandongtai',
                name: 'jituandongtai',
                component: jituandongtai,
            },
            {
                path: 'hangyedongtai',
                name: 'hangyedongtai',
                component: hangyedongtai,
            },
            {
                path: 'shipinzhongxin',
                name: 'shipinzhongxin',
                component: shipinzhongxin,
                // children: [{
                //     path: 'shipinItem',
                //     name: 'shipinItem',
                //     component: shipinItem,
                // }]
            },
            {
                path: 'xiaoqugushi',
                name: 'xiaoqugushi',
                component: xiaoqugushi,
                // children: [{
                //     path: 'xiaoquItem',
                //     name: 'xiaoquItem',
                //     component: xiaoquItem,
                // }]
            },

        ]
    },

    // 每一项
    {
        path: '/jituanItem',
        name: 'jituanItem',
        component: jituanItem,
    }
    ,
    {
        path: '/hangyeItem',
        name: 'hangyeItem',
        component: hangyeItem,
    }
    ,
    {
        path: '/shipinItem',
        name: 'shipinItem',
        component: shipinItem,
    }
    ,
    {
        path: '/xiaoquItem',
        name: 'xiaoquItem',
        component: xiaoquItem,
    },
    // 空白
    // {
    //     path: '/kongbai',
    //     name: 'kongbai',
    //     component: kongbai,
    // },

    // 业务体系
    {
        path: '/WorkingSystem',
        component: WorkingSystem,
        redirect: '/WorkingSystem/WorkingSY',
        children: [
            // 首页
            {
                path: 'WorkingSY',
                name: 'WorkingSY',
                component: WorkingSY,
                // hidden:true
            },

            // 每一项
            {
                path: 'WorkingItem',
                name: 'WorkingItem',
                component: WorkingItem,
                // hidden: true

            },
            // 学历教育
            {
                path: 'XLJY',
                component: XLJY,
                redirect: '/WorkingSystem/XLJY/CRGK',
                children: [
                    {
                        path: 'CRGK',
                        component: CRGK
                    },
                    {
                        path: 'ZK',
                        component: ZK
                    },
                    {
                        path: 'GJKF',
                        component: GJKF
                    },
                    {
                        path: 'DDZZ',
                        component: DDZZ
                    }
                ]
            },
            // 职称申报
            {
                path: 'ZCSB',
                component: ZCSB,
                redirect: '/WorkingSystem/ZCSB/ChuJi',
                children: [
                    {
                        path: 'ChuJi',
                        component: ChuJi
                    },
                    {
                        path: 'ZhongJi',
                        component: ZhongJi
                    },
                    {
                        path: 'GaoJi',
                        component: GaoJi
                    },
                    {
                        path: 'ZhengGaoJi',
                        component: ZhengGaoJi
                    },

                ]
            },
            // 职业教育
            {
                path: 'ZYJY',
                component: ZYJY,
                redirect: '/WorkingSystem/ZYJY/xuexiao',
                children: [
                    {
                        path: 'xuexiao',
                        component: xuexiao
                    },
                    {
                        path: 'peixun',
                        component: peixun
                    },

                ]
            },
            // 专升本
            {
                path: 'ZSB',
                component: ZSB,
            },
            // 成考院校
            {
                path: 'CKYX',
                component: CKYX,
                alwaysShow: true,
                meta: {
                    showfater: true
                },
                children: [{
                    path: 'xiangqing',
                    name: 'xiangqing',
                    component: xiangqing,
                    meta: {
                        showfater: false
                    }
                }]
            },

        ]
    },
    // 学盟服务

    {
        path: '/XMFU',
        component: XMFU,
        redirect: '/XMFU/sishi',
        children: [
            {
                path: 'sishi',
                name: 'sishi',
                component: sishi,
            },
            {
                path: 'xuanze',
                name: 'xuanze',
                component: xuanze,
            },
            {
                path: 'fengcai',
                name: 'fengcai',
                component: fengcai,
            }
        ]
    },
    // 团建
    {
        path: '/tuanjian',
        component: tuanjian,
    },
    // 联系我们
    {
        path: '/lianxi',
        component: lianxi,
    },
    // 用户服务
    // {
    //     path: '/User',
    //     component: User,
    // },
    {
        path: '/Chengkao',
        component: chengkao,
    },
    {
        path: '/Guojiakaifang',
        component: guojiakaifang,
    },
    {
        path: '/shenbao',
        component: shenbao,
    },



]
const router = new VueRouter({
    mode: 'history',
    routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default router