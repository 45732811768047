<template>
  <div class="xwzx_box">
    <div class="xwzx_img">
      <img :src="Image" alt="" class="img" />
    </div>
    <div class="el_menu_box">
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="#000"
        active-text-color="#9b0e12"
        router
      >
        <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
          {{ item.navItem }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="router_view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      activeIndex: "1",
      navList: [
        { name: "/NewsCenter/jituandongtai", navItem: "集团动态" },
        { name: "/NewsCenter/hangyedongtai", navItem: " 行业动态" },

        { name: "/NewsCenter/shipinzhongxin", navItem: "视频中心" },
        { name: "/NewsCenter/xiaoqugushi", navItem: "校区故事" },
      ],
      Image: "",
    };
  },
  created() {
    this.creat();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    async creat() {
      const { news } = await banner();
      this.Image = news;
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.xwzx_box {
  // 顶部照片
  .xwzx_img {
    height: 320px;
    width: 100%;
    // background-color: #9b0e12;
  }
  // 导航栏的页面
  .el_menu_box {
    width: 1400px;
    margin: 0 auto;
    margin-top: 105px;
    .el-menu-item {
      font-size: 19px;
      font-weight: 600;
    }
  }
  // 跳转路由的页面
  .router_view {
    margin: 0 auto;
    width: 1400px;
    // background-color: skyblue;
  }
}
</style>