<template>
  <div class="tuanjian">
    <!-- <div class="tuanjian_top">
      123
    </div> -->
    <div class="tuanjian_img">
      <img :src="Image" alt="" class="img">
    </div>
    <!-- 党建概括 -->
    <div class="tuanjian_gaikuo">
        <!-- <h1>{{xuemeng.title}}</h1> -->
        <div class="tuanjian_jieshao">&nbsp;&nbsp;&nbsp;&nbsp;
            {{xuemeng.content}}
        </div>
        <div class="tuanjian_gaikuo_img">
          <img :src="xuemeng.image" alt="" class="img">
        </div>
    </div>
     <!-- 公益活动 -->
    <div class="tuanjian_box_qixiapinpai">
      <h1 class="tuanjian_box_tuanjian_box_qixiapinpai_h1">公益活动</h1>
      <el-carousel
        trigger="click"
        height="380px"
        indicator-position="none"
        :interval="3000"
        :autoplay="false"
      >
        <el-carousel-item v-for="(item,index) in gongyihuodong" :key="index">
          <div class="qixiapinpai_box">
            <ul class="qixiapinpai_ul">
              <li v-for="(i,e) in item" :key="e">
                <div class="qixiapinpai_li_div">
                  <img class="img" :src="i.image" alt="">
                </div>
                <div class="title">
                  {{i.title}}
                </div>
              </li>
            </ul>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
     <!-- 党建动态 -->
    <div class="tuanjian_box_qixiapinpai" >
      <h1 class="tuanjian_box_tuanjian_box_qixiapinpai_h1">党建动态</h1>
      <el-carousel
        trigger="click"
        height="100%"
        indicator-position="none"
        :interval="3000"
        :autoplay="false"
        class="el-car"
      >
        <el-carousel-item  v-for="(item,index) in gongyihuodong" :key="index">
          <div class="qixiapinpai_box">
            <ul class="qixiapinpai_ul">
              <li v-for="(i,e) in item" :key="e">
                <div class="qixiapinpai_li_div">
                  <img class="img" :src="i.image" alt="">
                </div>
                <div class="title">
                  {{i.title}}
                </div>
              </li>
            </ul>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { banner } from "@/api/intoxuemeng";
import { partybuild} from "@/api/tuanjian";
export default {
  data() {
    return {
      xuemeng:{},
      huodong:{},
      gongyihuodong:[],
      dongtai:{},
      dangjiandongtai:[],
      Image: "",
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const xuemeng = await partybuild();
      this.xuemeng=xuemeng.intro
      this.huodong=xuemeng.activity
      this.dongtai=xuemeng.trends
      const { party } = await banner();
      this.gongyihuodong = this.group(this.gongyihuodong, this.huodong, 3); //新数组
      this.dangjiandongtai = this.group(this.dangjiandongtai, this.dongtai, 3); //新数组
      this.Image = party;
    },
     // 分组方法三个参数分别是 新数组，原数组，几个分为一组
    group(newArr, arr, num) {
      if (arr.length > 0) {
        if (arr.length > num) {
          //arr 的长度大于 分组数
          newArr.push(arr.splice(0, num)); //分组
          this.group(newArr, arr, num); //递归
        } else {
          //如果长度不够分为一组，就直接把剩余的分为一组
          newArr.push(arr.splice(0, arr.length));
        }
      }
      return newArr; //返回已分组的新数组
    },
    
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tuanjian {
  // .tuanjian_top{
  //   width: 1400;
  //   height: 300px;
  //   background-color: red;
  // }
  .tuanjian_img {
    height: 320px;
    width: 100%;
    // background-color: #9b0e12;
  }
//   党建概括
  .tuanjian_gaikuo{
    width: 1400px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 70px;
    h1{
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #30394d;
    }
    .tuanjian_jieshao{
        margin-top:50px;
        font-size: 20px;
        line-height: 30px;
    }
    .tuanjian_gaikuo_img{
        margin-top: 55px;
        background-color: red;
        width: 100%;
        height: 530px;
    }
  }
   // 轮播图 旗下品牌
  .tuanjian_box_qixiapinpai {
    // swiper左右按钮
    /deep/.el-carousel__arrow {
      background-color: #30384d;
      width: 60px;
      height: 60px;
      border-radius: 0px;
    }
    // 左右按钮图标
    /deep/.el-icon-arrow-left {
      font-size: 32px;
    }
    /deep/.el-icon-arrow-right {
      font-size: 32px;
    }
     /deep/ .el-car {
      width: 100%;
      height: 100%;
    }
    height: 400px;
    width: 1400px;
    margin: 0 auto;
    margin-bottom: 70px;

    .tuanjian_box_tuanjian_box_qixiapinpai_h1 {
      font-size: 40px;
      font-weight: 800;
      color: #30384d;
      margin-bottom: 50px;
      text-align: center;
    }
    .qixiapinpai_box {
      width: 1230px;
      height: 325px;
      margin: 0 auto;
      display: flex;
      .qixiapinpai_ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        li {
          width: 392px;
          height: 93%;
          background-color: #fff;
          border: 1px solid #9aa7d0;
          display: flex;
          flex-direction: column;
          .qixiapinpai_li_div {
            height: 234px;
            width: 100%;
            background-color: #9b0e12;
          }
          .title{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>