import request from '@/utils/request'
// 轮播图
export  function lunbotu() {
    return request({
      url: 'api/api/banner',
      method: 'post'
    })
  }

  // 集团简介
  export function jituanjianjie() {
    return request({
      url: 'api/api/xuemengintro',
      method: 'post'
    })
  }
  // 视频
  export function shipinshouye() {
    return request({
      url: 'api/api/homevideo',
      method: 'post'
    })
  }
  // 业务体系
  export function ywtx() {
    return request({
      url: 'api/api/business',
      method: 'post'
    })
  }
  //四师服务
  export function ssfw() {
    return request({
      url: 'api/api/teacher4srv',
      method: 'post'
    })
  }
  //集团荣誉
  export function jjrongyu() {
    return request({
      url: 'api/api/companyhonour',
      method: 'post'
    })
  }
  //旗下品牌
  export function qxpp() {
    return request({
      url: 'api/api/brands',
      method: 'post'
    })
  }
  export function school() {
    return request({
      url: 'api/api/school',
      method: 'post'
    })
  }
  export function homeimage() {
    return request({
      url: 'api/api/homeimage',
      method: 'post'
    })
  }